<template>
    <div class="container">
        <div class="filters">
            <!-- Arama Input -->
            <el-input v-model="searchQuery" placeholder="Search by Name, Phone, Serial Number..." clearable
                style="width: 300px; margin-right: 10px" @keyup.enter="fetchData"></el-input>

            <el-button type="primary" @click="fetchData">
                Search
            </el-button>
        </div>

        <!-- Kullanıcı Aktivasyon Tablosu -->
        <el-table :data="activations" v-loading="loading" style="margin-top: 20px" border>
            <!-- <el-table-column prop="id" label="ID" width="80"></el-table-column> -->
            <el-table-column prop="firstName" label="First Name" width="150"></el-table-column>
            <el-table-column prop="lastName" label="Last Name" width="150"></el-table-column>
            <el-table-column prop="phoneNumber" label="Phone" width="170"></el-table-column>
            <el-table-column prop="email" label="Email" width="250"></el-table-column>
            <el-table-column prop="caneSerialNumber" label="Serial Number" width="200"></el-table-column>
            <el-table-column prop="activationCode" label="Activation Code" width="140"></el-table-column>
             <el-table-column prop="address" label="Address" width="150"></el-table-column> 
            <el-table-column prop="isActive" label="Is Active" width="70">
                <template #default="{ row }">
                    <el-tag :type="row.isActive ? 'success' : 'danger'">
                        {{ row.isActive ? 'Active' : 'Inactive' }}
                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column label="Actions" width="100">
                <template #default="{ row }">
                    <el-button type="primary" size="mini" @click="showDetails(row.id)">Details</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- Aktivasyon Detayları Dialog -->
        <el-dialog title="Activation Versions" :visible.sync="detailsDialogVisible" width="60%">
            <el-table :data="activationVersions" v-loading="loadingDetails" border>
                <!-- <el-table-column prop="id" label="Version ID" width="150"></el-table-column> -->
                <el-table-column prop="mac" label="MAC Address" width="200"></el-table-column>
                <el-table-column prop="mainAppVersion" label="Main App Version" width="150"></el-table-column>
                <el-table-column prop="wirelessVersion" label="Wireless Version" width="150"></el-table-column>
                <el-table-column prop="fusVersion" label="FUS Version" width="150"></el-table-column>
                <el-table-column prop="appVersion" label="App Version" width="150"></el-table-column>
                <el-table-column prop="createdAt" label="Created At" width="200">
                    <template #default="{ row }">
                        {{ new Date(row.createdAt).toLocaleString() }}
                    </template>
                </el-table-column>
            </el-table>
            <template #footer>
                <el-button @click="detailsDialogVisible = false">Close</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { ActivationApi } from "@/wwapi/apis/activation-api"; // Activation API sınıfını import et

export default {
    data() {
        return {
            searchQuery: "", // Arama metni
            filters: {
                isActive: null, // Aktiflik filtresi
            },
            activations: [], // Aktivasyon listesi
            activationVersions: [], // Aktivasyon detayları
            loading: false, // Aktivasyon listesi yükleme durumu
            loadingDetails: false, // Aktivasyon detayları yükleme durumu
            detailsDialogVisible: false, // Detay dialog görünürlüğü
        };
    },
    methods: {
        // Aktivasyonları Getir
        async fetchData() {
            this.loading = true;
            const activationApi = new ActivationApi();
            try {
                const requestBody = {
                    searchString: this.searchQuery, // Arama metni
                };
                const response = await activationApi.searchUserCaneActivations(requestBody);
                this.activations = response.data.data;
            } catch (error) {
                console.error("Error fetching activations:", error);
            } finally {
                this.loading = false;
            }
        },
        // Detayları Göster
        async showDetails(activationId) {
            this.loadingDetails = true;
            this.detailsDialogVisible = true;
            const activationApi = new ActivationApi();
            try {
                const response = await activationApi.getActivationVersions(activationId);
                this.activationVersions = response.data.data;
            } catch (error) {
                console.error("Error fetching activation details:", error);
                this.activationVersions = [];
            } finally {
                this.loadingDetails = false;
            }
        },
    },
    mounted() {
        this.fetchData(); // Sayfa yüklendiğinde veri getir
    },
};
</script>

<style lang="scss" scoped>
.container {
    padding: 20px;
}

.filters {
    display: flex;
    align-items: center;
}
</style>