var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "filters" },
        [
          _c("el-input", {
            staticStyle: { width: "300px", "margin-right": "10px" },
            attrs: {
              placeholder: "Search by Name, Phone, Serial Number...",
              clearable: "",
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.fetchData.apply(null, arguments)
              },
            },
            model: {
              value: _vm.searchQuery,
              callback: function ($$v) {
                _vm.searchQuery = $$v
              },
              expression: "searchQuery",
            },
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
            [_vm._v(" Search ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "20px" },
          attrs: { data: _vm.activations, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "firstName", label: "First Name", width: "150" },
          }),
          _c("el-table-column", {
            attrs: { prop: "lastName", label: "Last Name", width: "150" },
          }),
          _c("el-table-column", {
            attrs: { prop: "phoneNumber", label: "Phone", width: "170" },
          }),
          _c("el-table-column", {
            attrs: { prop: "email", label: "Email", width: "250" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "caneSerialNumber",
              label: "Serial Number",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "activationCode",
              label: "Activation Code",
              width: "140",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "address", label: "Address", width: "150" },
          }),
          _c("el-table-column", {
            attrs: { prop: "isActive", label: "Is Active", width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: row.isActive ? "success" : "danger" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.isActive ? "Active" : "Inactive") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Actions", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.showDetails(row.id)
                          },
                        },
                      },
                      [_vm._v("Details")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Activation Versions",
            visible: _vm.detailsDialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailsDialogVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.detailsDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingDetails,
                  expression: "loadingDetails",
                },
              ],
              attrs: { data: _vm.activationVersions, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "mac", label: "MAC Address", width: "200" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "mainAppVersion",
                  label: "Main App Version",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "wirelessVersion",
                  label: "Wireless Version",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fusVersion",
                  label: "FUS Version",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "appVersion",
                  label: "App Version",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "createdAt", label: "Created At", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(new Date(row.createdAt).toLocaleString()) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }